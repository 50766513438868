<template>
  <div class="_bio relative shadow-2xl">
    <!-- PROFILE IMAGE -->
    <img :src="imagePath" alt="bio-photo" class="h-56" :class="{'_third-image': secondImage, '_image': !secondImage}"/>
    <img v-if="secondImage" :src="secondImagePath" alt="bio-photo" class="h-56 _second-image"/>
    <div class="_info rounded-lg pt-20">
        <h1 class="font-semibold text-2xl pb-3">{{ name }}</h1>
        <p class="px-5 text-left text-sm">{{ text }}</p>

        <div class="_video">
            <VideoModal :videoSrc="videoSrc"/>
        </div>
    </div>
  </div>
</template>

<script>
    import VideoModal from '@/components/VideoModal'

    export default {
        components: {
            VideoModal
        },

        props: {
            image: String,
            videoSrc: String,
            name: String,
            text: String,
            secondImage: String
        },

        computed: {
            imagePath() {
                if (!this.image) {
                    return
                }

                let images = require.context('../assets/images/', false, /\.png$/)
                return images('./' + this.image + ".png")
            },
            secondImagePath() {
                if (!this.secondImage) {
                    return
                }

                let images = require.context('../assets/images/', false, /\.png$/)
                return images('./' + this.secondImage + ".png")
            }
        }

    }
</script>

<style lang='scss' scoped>
    ._bio {
        position: relative;
    }

    ._info {
        width: 300px;
        height: 300px;
        background-color: white;
    }

    ._image {
        position: absolute;
        top: -130px;
        left: 50px;
        width: 200px;
        height: 200px;
    }

    ._video {
        position: absolute;
        bottom: 8px;
        left: 100px;
    }

    ._second-image {
        position: absolute;
        top: -130px;
        left: -75px;
        width: 200px;
        height: 200px;
    }

    ._third-image {
        position: absolute;
        top: -130px;
        left: 175px;
        width: 200px;
        height: 200px;
    }
</style>
