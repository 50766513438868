<template>
  <div class="_default">
    <Header/>

    <router-view/>
  </div>
</template>

<script>
  import Header from '@/components/Header'

  export default {
    components: {
      Header
    },

    mounted() {
      let html = document.getElementsByTagName("html")[0]
      let att = document.createAttribute("style")
      att.value = "scroll-behavior:smooth;"
      html.setAttributeNode(att);   
    }
  }
</script>

<style lang='scss' scoped>
  ._default {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  html {
    scroll-behavior: smooth;
  }
</style>