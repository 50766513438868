<template>
  <div>
    <div id="top" class="_hero pt-4rem shadow-2xl"></div>
    <div class="_content px-5 mx-auto">
      <!-- INTRO VIDEO -->
      <div class=" max-w-xl mx-auto mt-5">
        <div class="my-10">
          <h1 class="text-2xl font-semibold">Na lazy!</h1>
          <p>Medzigeneračný putovný výtvarný tábor</p>
        </div>
        <iframe class="_main-video" src="https://www.youtube.com/embed/Wfy4rJrH4uM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="max-w-4xl mx-auto py-10">
        Tábor Na Lazy! je experiment. Leto, lazy, lúka,  jurta, deti, starí rodičia, umenie a okolité vmeny.
        V tomto nastavení máme úlohu týždeň spoločne tvoriť, hrať sa a starať sa o seba. Rozprestrieť ruky, nadýchnuť sa a rozbehnúť dole kopcom, zájsť do lesa, vnímať zvuky, vône, vetrík, niekde breše pes. Som už hladná, babina máš niečo? O chvíľu príde ďalší lektor. Pozri, pozri, toto je sluka, idem to zapísať do denníka..
        Prechádzame médiami a žánrami, spoznávame byliny a okolité vtáky. Spoločne tancujeme a spievame, maľujeme, premiestňujeme kamene a šišky, pečieme chleby a zdobíme detvianske kríže.
        Toto je spomienka na multižánrový multimediálny medzigeneračný umelecký tábor Na Lazy! 22.7 - 27.7 2019, Detva.
      </div>
    </div>

    <!-- BIOS -->
    <div id="about" class="_bios my-8 py-8 background">
      <div class="relative">
        <h1 class="font-bold text-2xl mb-40 text-white">Naši lektori</h1>

        <div class="flex flex-row justify-between max-w-6xl mx-auto">
          <Bio
            image='katka-bio'
            name="Katarína Škamlová"
            text="Grafička a hlavná organizátorka tábora. V súčastnosti žije v Bratislave a pracuje na sebe. Realizuje a dokončuje vlastnú knižku."
            videoSrc="https://www.youtube.com/embed/WPsLx3PcIR8"
          />
          <Bio
            image='lujza-bio'
            name="Lujza Srnková"
            text="Maliarka a hlavná organizátorka tábora. V súčastnosti žije v Prahe, kde učí maľbu a kresbu mládež a dospelých."
            videoSrc="https://www.youtube.com/embed/WPsLx3PcIR8"
          />
          <Bio
            image='hneda-bio'
            name="Stanislav Abrahám"
            text="Audiovizuálny performer, zvukový umelec a pedagóg. Stanislav je zvukový designér, vyštudoval FAMU v Prahe a v súčasnosti pôsobí ako zvukový designér v Českom rozhlase."
            videoSrc="https://www.youtube.com/embed/_lQ9XNIM8Lw"
          />
        </div>

        <div class="flex flex-row justify-between max-w-6xl mx-auto mt-40">
          <Bio
            image="modra-bio"
            name="Eva Priečková"
            text="Jej hlavnou fascináciou sú umelecké presahy od tanca smerom k experimentálnej hudbe, pozorovaniu pohybov v prírode, k pohybovým inštaláciám a fyzickým performance vo verejných priestoroch."
            videoSrc="https://www.youtube.com/embed/i2FZUu92z_Q"
          />
          <Bio
            image="stanislav-bio"
            name="Milan Malček"
            text="Je hlavne reštaurátor detvianskych krížov, umelecký rezbár ale aj organizátor umeleckých sympózií."
            videoSrc="https://www.youtube.com/embed/_Ve-BEJ60X0"
          />
          <Bio
            image="papo-bio"
            name="Martin Hrvol"
            text="V súčastnosti dokončuje štúdium sochy na VŠVU, laureát ceny EXIT 2019. Slušný človek a skvelý priateľ."
            videoSrc="https://www.youtube.com/embed/Ymm6ezkmRtY"
          />
        </div>

        <div class="flex flex-row justify-between max-w-6xl mx-auto mt-40">
          <Bio
            image="oranzova-bio"
            name="Dušan Kerestúr"
            text="Ornitológ"
            videoSrc="https://www.youtube.com/embed/K3iffkpvrn4"
          />
          <Bio
            image="otec-bio"
            second-image="cervena-bio"
            name="Ľudmila Hoffmanová / Ivan Škamla"
            text="Ľudmila je propagátorka zdravej jednoduchej stravy a popri tom vedie workshopy pečenia, kde sa jej žiakom stal aj Ivan, renesančný človek, prioritne sa však venuje hudbe."
            videoSrc="https://www.youtube.com/embed/kca7Qaxrc7M"
          />
          <Bio
            image="zelena-bio"
            name="Lucia Bencze Žilková"
            text="Bylinkárka, jogýnka, bežkyňa, otužilkyňa a mamina s nespútaným srdcom."
            videoSrc="https://www.youtube.com/embed/USUJ_7la9G8"
          />
        </div>
      </div>
    </div>

    <h1 class="text-2xl font-semibold pt-10">Aj takto sme sa mali ...</h1>
    <!-- PHOTOS -->
    <ImageCarousel class="py-10"/>

    <!-- FOOTER -->
    <Footer/>
  </div>
</template>

<script>
  import Bio from '@/components/Bio'
  import ImageCarousel from '@/components/ImageCarousel'
  import Footer from '@/components/Footer'

  export default {
    components: {
      Bio,
      ImageCarousel,
      Footer
    }
  }
</script>

<style lang='scss' scoped>
  .background {
    // set to hidden to sharpen the edges
    overflow: hidden;
    position: relative;
  }

  ._bios {
    margin: 0 -10px;
  }

  .background::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-image: url('../assets/images/bio.jpg');
    filter: blur(5x);
    -webkit-filter: blur(5px);
  }

  ._hero {
    height: 40rem;
    background: url('../assets/images/homepage.jpg');
    background-size: cover;
    background-position: 50% 62%;
  }

  ._content {
    max-width: 1440px;
  }

  ._frame {
    @apply shadow-2xl w-2/3 bg-gray-200 mt-20;
    height: 640px;
  }

  ._main-video {
    @apply w-full;
    height: 24rem;
  }
</style>
