<template>
  <div @keydown.esc.exact="isOpen = false" tabindex="0">
    <!-- SIZING BUTTON -->
    <a @click="isOpen=!isOpen" class="cursor-pointer text-blue-500 no-underline">
      Moje video >
    </a>

    <!-- SIZING MODAL -->
    <div v-if="isOpen" class="fixed flex justify-around inset-0 z-50 overflow-y-auto _backdrop">
      <div class="bg-white w-full max-w-2xl mx-auto rounded my-auto">
        <div class="flex flex-start items-center border-b border-grey-light py-4 px-5">
          <div class="font-bold">
            Moje video
          </div>
          <button class="text-grey font-bold text-xl ml-auto" @click="isOpen=!isOpen">×</button>
        </div>

        <iframe class="w-full _video" :src="videoSrc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <div class="bg-grey-lighter modal-body px-8 py-6 overflow-auto">
          <a class="text-center w-full md:w-1/6 mt-4 md:mt-6 mx-auto" @click="isOpen=!isOpen">Zavriet</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                isOpen: false
            }
        },
        props: {
            videoSrc: String
        }
    }
</script>

<style lang="scss" scoped>
    ._backdrop {
        background-color: rgba(0, 0, 0, 0.6);
    }

    ._video {
        height: 30rem;
    }
</style>