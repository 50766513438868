<template>
    <nav class="_header px-20 shadow-md inline-flex w-full text-white">
        <div class="-mb-px flex justify-center w-full _navigation-items pt-2">
            <!-- NAVIGATION ITEMS -->
            <a class="_item" href="#top">
                Domov
            </a>
            <a class="_item" href="#about">
                O nás
            </a>
            <a class="_item" href="#photos">
                Fotky
            </a>
            <a class="_item" href="#contact">
                Kontakt
            </a>
        </div>
    </nav>
</template>

<script>
    export default {
    }
</script>

<style scoped>
    ._header {
        font-size: 16px;
        position: relative;
        background: rgba(0, 0, 0, 0.6);

        height: 4rem;
        display: flex;
        align-items: center;
        position: fixed;
        width: 100%;
        z-index: 50;
    }

    ._navigation-items {
        padding-right: 9rem;
    }

    ._item {
        @apply no-underline border-b-2 border-transparent uppercase tracking-wide font-semibold text-xs py-3 px-4 text-base;
    }
</style>
