<template>
  <div id="contact" class="_footer text-white px-4 py-5">
    <div class="_footer-content mx-auto flex flex-row justify-between items-center">

      <!-- INFO -->
      <div class="flex flex-col text-left pb-3">
        <h2 class="font-semibold text-lg italic pb-5">Organizátori</h2>
        <div class="flex flex-col">
          <div class="pb-4">
            <div class="font-semibold pb-1">
              Lujza Srnková
            </div>
            <a href="mailto:lujza.srnkova@gmail.com">
              <div class="flex items-center">
                <div class="_icon-background">
                  <icon class="text-lg" :icon="['fas', 'envelope']"/>
                </div>
                <p class="pl-1">lujza.srnkova@gmail.com</p>
              </div>
            </a>
          </div>

          <div>
            <div class="font-semibold pb-1">
              Katka Škamlová
            </div>
            <a href="mailto:katarina.skamlova@gmail.com">
              <div class="flex items-center">
                <div class="_icon-background">
                  <icon class="text-lg" :icon="['fas', 'envelope']"/>
                </div>
                <p class="pl-1">katarina.skamlova@gmail.com</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div>
        <!-- SOCIAL NETWORKS -->
        <div class="flex justify-between items-center">
          ( ♪ .♬..  ♪..
          <!-- FACEBOOK -->
          <SocialNetworkIcon
              title="Nalazy na Facebooku"
              href="https://www.facebook.com/Na-Lazy-106895400670385"
              iconName="facebook"
          />
          .♪ .. ♪..
          <!-- YOUTUBE -->
          <SocialNetworkIcon
              title="Nalazy na Youtube"
              href="https://www.youtube.com/channel/UCbxwweh8NnQzdYCuzJLs7Ew"
              iconName="youtube"
          />
          ♫ .♪ .. ♪..♪.♬. ♫ .♪
          <!-- INSTAGRAM -->
          <SocialNetworkIcon
              title="Nalazy na Instagrame"
              href="https://www.instagram.com/tabor.nalazy/"
              iconName="instagram"
          /> ♪ ♪..  ♬ )
        </div>

        <!-- BIRDS -->
        <pre class="_birds">
        ___ |      ___ \        ___       \ ___
        (' v ')    (' v ')      (- v -)    (' v ')
        (( __ ))   (( __ ))     (( __ ))   (( __ ))
        ^ ^        ^ ^          ^ ^        ^ ^
        </pre>

        <div class="_fond flex flex-row items-center ml-auto">
          <p class="text-left">Tento projekt z verejných zdrojov podporil Fond na podporu umenia</p>
          <img class="h-16 pl-2" src="../assets/images/fond.jpg" alt="font na podporu umenia"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import SocialNetworkIcon from "@/components/SocialNetworkIcon"

  export default {
    components: {
      SocialNetworkIcon
    }
  }
</script>

<style lang="scss" scoped>
  ._footer {
    background-color: #204243;
    @apply w-full;
  }

  ._footer-content {
    max-width: 75rem;
    border-color: rgba(0, 0, 0, 0.3);
    border-left-width: 0.15rem;
    border-right-width: 0.15rem;
    @apply px-10 py-4;
  }

  ._icon-background {
    width: 35px;
    height: 35px;
    background-color: rgba(0, 0, 0, 0.3);
    @apply flex p-2 rounded items-center justify-center;
  }

  ._icon-background:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  ._fond {
    max-width: 450px;
  }
</style>
