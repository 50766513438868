<template>
  <div>
    <a class="flex items-center" :title="title" :href="href">
      <div class="_icon-background">
        <icon class="text-xl" :icon="['fab', iconName]" />
      </div>
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      href: String,
      iconName: String,
      text: String
    }
  }
</script>

<style lang="scss" scoped>
  ._icon-background {
    width: 35px;
    height: 35px;
    background-color: rgba(0, 0, 0, 0.3);
    @apply flex p-2 rounded items-center justify-center;
  }

  ._icon-background:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
</style>
