<template>
  <div id="photos" class="mx-auto mb-10">

    <div class="flex justify-around items-center">
      <!-- LEFT ARROW -->
      <a class="_arrow" :disabled="page === 0" :class="{'is-disabled': page === 0}" @click="prev()">
        <i class="prev"/>
      </a>

      <!-- IMAGES -->
      <div class="_pageImages">
        <div class="wrapper">
          <transition class="" :name="slideDirection">
            <div class="carousel" :key="page">
              <img
                  :style="{'width':'calc(100%/' + dimensions[0] + ')', 'height':'calc(100%/' + dimensions[1] + ')'}"
                  class="p-2 inline-block"
                  v-for="url in imagePageUrls"
                  :src="url"
              />
            </div>
          </transition>
        </div>
      </div>

      <!-- RIGHT ARROW -->
      <a class="_arrow" :disabled="page === lastPage" :class="{'is-disabled': page === lastPage}" @click="next()">
        <i class="next"/>
      </a>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        // row, column
        dimensions: [4, 3],
        numPerPage: 12,
        numOfPhotos: 62, // TODO
        // imageUrls: [],
        imagePageUrls: [],
        page: 0,
        bucketUrl: 'https://storage.googleapis.com/nalazy_bucket/',
        isLoading: false,
        slideDirection: 'next',
        imagesPerPage: []
        // verticalImages: []
      }
    },

    methods: {
      setImageUrls() {
        const times = x => f => {
          if (x > 0) {
            f()
            times (x - 1) (f)
          }
        }

        // TODO
        let counter = 1
        let images = []
        times(30) (() => {
          images.push(this.bucketUrl + counter.toString() + '.jpg')
          counter += 1
        })
        this.imagesPerPage = this.createChunks(images)

        // TODO
        counter = 1
        images = []
        times(32) (() => {
          images.push(this.bucketUrl + counter.toString() + '_V.jpg')
          counter += 1
        })
        let v_images = this.createChunks(images)
        this.imagesPerPage = this.imagesPerPage.concat(v_images)

        // let x = this.imagesPerPage
        // debugger
      },

      async next() {
        if(this.page + 1 > this.lastPage) {
          return
        }
        this.page += 1
        this.slideDirection = 'next'
        this.isLoading = true
        // debugger
        await this.loadImages()
        this.imagePageUrls = this.imagesPerPage[this.page]//this.imageUrls.slice((this.numPerPage * (this.page + 1)), this.numPerPage + (this.numPerPage * (this.page + 1)))

        this.isLoading = false;

        // this.page += 1
      },

      async prev() {
        if(this.page - 1 < 0) {
          return
        }
        this.page -= 1
        this.slideDirection = 'prev'
        this.isLoading = true
        await this.loadImages()
        this.imagePageUrls = this.imagesPerPage[this.page]//this.imageUrls.slice((this.numPerPage * (this.page - 1)), this.numPerPage + (this.numPerPage * (this.page - 1)))

        this.isLoading = false;

        // this.page -= 1
      },

      async loadImages() {
        for (const imageSrc of this.imagesPerPage[this.page]) {
          const img = new Image()
          img.src = imageSrc
        }
      },

      createChunks(list) {
        return new Array(Math.ceil(list.length / this.numPerPage)).fill().map(_ => list.splice(0, this.numPerPage))
      }
    },

    computed: {
      lastPage() {
        return Math.ceil(this.numOfPhotos / this.numPerPage) - 1
      }
    },

    async mounted() {
      this.setImageUrls()
      // this.imageUrls = this.imageUrls.concat(this.verticalImages)
      // debugger
      this.imagePageUrls = this.imagesPerPage[0]//this.imageUrls.slice(0, this.numPerPage)
    },

    watch: {
      page(value) {
        if (value > 2) {
          this.dimensions = [6, 2]
        } else {
          this.dimensions = [4, 3]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* LAYOUT */
  .wrapper{
    position: relative;
    overflow: hidden;
  }

  .carousel{
    display: -webkit-box;
    position: relative;
    width: 100%;
    height: 40rem;
  }

  ._pageImages {
    width: 80rem;
  }

  /* SLIDE ANIMATIONS */
  .next-enter-active {
    animation: moveIn 1.5s;
  }

  .next-leave-active {
    animation: moveOut 1.5s;
    /*animation-timing-function: cubic-bezier(0.3, 1.0, 0.3, 1.0);*/
  }

  .prev-enter-active {
    animation: moveOut 1.5s reverse;
  }

  .prev-leave-active {
    animation: moveIn 1.5s reverse;
  }

  .next-leave-active,
  .prev-leave-active {
    position: absolute;
  }

  @keyframes moveOut {
    0% { transform: scale(1) }
    25%  { transform: scale(0.7) translate(0, 0)}
    100% { transform: scale(0.7) translate(-200%, 0)}
  }

  @keyframes moveIn {
    0% { transform: scale(0.7) translate(200%, 0)}
    25% { transform: scale(0.7) translate(200%, 0)}
    75%  { transform: scale(0.7) translate(0, 0)}
    100% { transform: scale(1) }
  }

  /* NEXT & PREVIOUS */
  .next {
    left: 10px;
    @apply absolute border-gray-700;
    transform: rotate(-45deg);
  }

  .prev {
    right: 10px;
    @apply absolute border-gray-700;
    transform: rotate(135deg);
  }

  i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 10px;
  }

  ._arrow {
    @apply flex relative justify-around items-center h-12 w-12 bg-gray-200 rounded-full text-center;
  }

  ._arrow:hover {
    background-color: rgba(0,0,0,0.3);
  }
</style>
